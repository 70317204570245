import { route, number, string } from 'react-router-typesafe-routes/dom'

/**
 * The use react-router-typesafe-routes library to have type safety for all route params
 * (path params, search params (including multiple keys), state, and hash)
 * on building and parsing URL parts and state objects.
 * Usage:
 * Absolute path:
 *  route: ROUTES.STORE.path -> /store/:id
 *  link: ROUTES.STORE.buildPath({id: 1}) -> /store/1
 * Relative path:
 *  route: ROUTES.STORE.relativePath -> store/:id
 *  link: ROUTES.STORE.buildRelativePath({id: 1}) -> store/1
 *
 * see: https://github.com/fenok/react-router-typesafe-routes
 */
export const ROUTES = {
  HOME: route(''),
  AUTH: route(
    'auth',
    {},
    {
      LOGIN: route('login', { searchParams: { error: string() } }),
      LOGOUT_SUCCESS: route('logout-success'),
    },
  ),
  SSO: route('sso', {}, { CALLBACK: route('callback') }),
  API: route(
    'api',
    {},
    {
      AUTH: route(
        'auth',
        {},
        { LOGIN: route('login'), LOGOUT: route('logout') },
      ),
    },
  ),
  MS_AUTH: route(
    'ms-auth',
    {},
    {
      CALLBACK: route('callback'),
    },
  ),
  GOOGLE_AUTH: route(
    'google-auth',
    {},
    {
      CALLBACK: route('callback'),
    },
  ),
  GOOGLE_AUTH_BUSINESS_CALLBACK: route('google-auth-business-callback'),
  DATA_PRIVACY: route('data-privacy'),
  CHOOSE_STORE: route('choose-store'),
  COMPANY: route(
    'company/:id',
    { params: { id: number() } },
    {
      SETTINGS: route(
        'settings',
        { searchParams: { storeId: number() } },
        {
          EMPLOYEES: route('employees'),
          ERP: route('erp-sync'),
          USER_ABSENCES: route('absence'),
          STORES: route('stores'),
          GOOGLE_BUSINESS: route('google-business', {
            searchParams: { error: string() },
          }),
        },
      ),
    },
  ),
  STORE: route(
    'store/:id',
    {
      params: { id: number() },
    },
    {
      DASHBOARD: route(
        'dashboard',
        { searchParams: { employeeId: number() } },
        {
          MY_APPOINTMENTS: route('my-appointments'),
        },
      ),
      NEW_APPOINTMENT: route(
        'new-appointment',
        {},
        { CONFIRM: route('confirm') },
      ),
      APPOINTMENT_DETAILS: route(
        'appointment/:appointmentId',
        {
          params: {
            appointmentId: number(),
          },
        },
        {
          RESCHEDULE: route('reschedule', {}, { CONFIRM: route('confirm') }),
        },
      ),
      ONBOARDING_SUCCESS: route('onboarding-success'),
      STORE_SETTINGS: route(
        'store-settings',
        {},
        {
          BASIC: route('basic-info'),
          SERVICE_TYPES: route('services'),
          EMPLOYEES: route('employees'),
          ROOMS: route('rooms'),
          GOOGLE_MAPS: route('location'),
        },
      ),
      EMPLOYEE_SETTINGS: route(
        'employee-settings',
        {},
        {
          MY_WORKING_HOURS: route('my-working-hours'),
        },
      ),
      CALENDAR_SETTINGS: route(
        'calendar-settings',
        {},
        {
          CALENDAR_SYNC: route('sync', { searchParams: { error: string() } }),
        },
      ),
    },
  ),
  ONBOARDING: route(
    'onboarding/:storeId',
    {
      params: { storeId: number() },
    },
    {
      WELCOME: route('welcome'),
      BASIC_INFO: route('basic-info'),
      SERVICES: route('services'),
      EMPLOYEES: route('employees'),
      ROOMS: route('rooms'),
      GOOGLE_MAPS: route('location'),
    },
  ),
  EMPLOYEE_ONBOARDING: route(
    'employee-onboarding/:storeId',
    { params: { storeId: number() } },
    {
      MY_WORKING_HOURS: route('my-working-hours'),
    },
  ),
}
